@font-face {
  font-family: "Poppins-Bold";
  src: url(/static/media/Poppins-Bold.404e299b.ttf) format("truetype"),
    url(/static/media/Poppins-Bold.404e299b.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(/static/media/Poppins-Regular.8081832f.ttf) format("truetype"),
    url(/static/media/Poppins-Regular.8081832f.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow-Bold";
  src: url(/static/media/Barlow-Bold.006f5d32.woff2) format("woff2"),
    url(/static/media/Barlow-Bold.6298a0c6.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow-Medium";
  src: url(/static/media/Barlow-Medium.5550c38f.woff2) format("woff2"),
    url(/static/media/Barlow-Medium.28798c88.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Medium";
  src: url(/static/media/AvenirLTStd-Medium.7550428d.woff2) format("woff2"),
    url(/static/media/AvenirLTStd-Medium.b2e85058.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Roman";
  src: url(/static/media/AvenirLTStd-Roman.5101dde9.woff2) format("woff2"),
    url(/static/media/AvenirLTStd-Roman.2e560e1a.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Black";
  src: url(/static/media/AvenirLTStd-Black.ca96bbd3.woff2) format("woff2"),
    url(/static/media/AvenirLTStd-Black.cbbaf68d.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-SuperItalic";
  src: url(/static/media/DrukText-SuperItalic.8b85c3b1.woff2)
      format("woff2"),
    url(/static/media/DrukText-SuperItalic.33a6234b.woff) format("woff"),
    url(/static/media/DrukText-SuperItalic.5b49ef1a.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-Super";
  src: url(/static/media/DrukText-Super.e0a60c38.woff2) format("woff2"),
    url(/static/media/DrukText-Super.0c8e1ced.woff) format("woff"),
    url(/static/media/DrukText-Super.203b905f.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-Heavy";
  src: url(/static/media/DrukText-Heavy.e604e5a3.woff2) format("woff2"),
    url(/static/media/DrukText-Heavy.2e540ca4.woff) format("woff"),
    url(/static/media/DrukText-Heavy.90e56637.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-HeavyItalic";
  src: url(/static/media/DrukText-HeavyItalic.edcc7e5e.woff2)
      format("woff2"),
    url(/static/media/DrukText-HeavyItalic.5d98575a.woff) format("woff"),
    url(/static/media/DrukText-HeavyItalic.24e524db.ttf)
      format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-Medium";
  src: url(/static/media/DrukText-Medium.1cd20f96.woff2) format("woff2"),
    url(/static/media/DrukText-Medium.b285d13e.woff) format("woff"),
    url(/static/media/DrukText-Medium.ed546d1d.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-Bold";
  src: url(/static/media/DrukText-Bold.971d08df.woff2) format("woff2"),
    url(/static/media/DrukText-Bold.4dffd607.woff) format("woff"),
    url(/static/media/DrukText-Bold.d5a8a3f9.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-BoldItalic";
  src: url(/static/media/DrukText-BoldItalic.e8dd2151.woff2)
      format("woff2"),
    url(/static/media/DrukText-BoldItalic.f8838768.woff) format("woff"),
    url(/static/media/DrukText-BoldItalic.2bf10650.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukText-MediumItalic";
  src: url(/static/media/DrukText-MediumItalic.42968d74.woff2)
      format("woff2"),
    url(/static/media/DrukText-MediumItalic.09f9e7f3.woff) format("woff"),
    url(/static/media/DrukText-MediumItalic.aa0cc7e4.ttf)
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-Medium";
  src: url(/static/media/DrukTextWide-Medium.ab59a255.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-Medium.71487074.woff) format("woff"),
    url(/static/media/DrukTextWide-Medium.ca25b26f.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-MediumItalic";
  src: url(/static/media/DrukTextWide-MediumItalic.5d040292.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-MediumItalic.7c16485e.woff)
      format("woff"),
    url(/static/media/DrukTextWide-MediumItalic.401c5db1.ttf)
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-Super";
  src: url(/static/media/DrukTextWide-Super.50cdaf38.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-Super.8cea98cd.woff) format("woff"),
    url(/static/media/DrukTextWide-Super.7f756485.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-SuperItalic";
  src: url(/static/media/DrukTextWide-SuperItalic.ec59f552.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-SuperItalic.bc24da7b.woff)
      format("woff"),
    url(/static/media/DrukTextWide-SuperItalic.5a57ef8b.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-Bold";
  src: url(/static/media/DrukTextWide-Bold.ed193546.woff2) format("woff2"),
    url(/static/media/DrukTextWide-Bold.9cec1d2c.woff) format("woff"),
    url(/static/media/DrukTextWide-Bold.ed0b6f56.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-BoldItalic";
  src: url(/static/media/DrukTextWide-BoldItalic.a0f4bb7d.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-BoldItalic.e2d26440.woff)
      format("woff"),
    url(/static/media/DrukTextWide-BoldItalic.02cb8be7.ttf)
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-HeavyItalic";
  src: url(/static/media/DrukTextWide-HeavyItalic.80775871.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-HeavyItalic.17ff8e15.woff)
      format("woff"),
    url(/static/media/DrukTextWide-HeavyItalic.88b4a210.ttf)
      format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukTextWide-Heavy";
  src: url(/static/media/DrukTextWide-Heavy.59ead822.woff2)
      format("woff2"),
    url(/static/media/DrukTextWide-Heavy.44cebeaf.woff) format("woff"),
    url(/static/media/DrukTextWide-Heavy.fc61b4c7.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-Medium";
  src: url(/static/media/Druk-Medium.2f9087e8.woff2) format("woff2"),
    url(/static/media/Druk-Medium.ae77bf61.woff) format("woff"),
    url(/static/media/Druk-Medium.3918bdff.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-HeavyItalic";
  src: url(/static/media/Druk-HeavyItalic.d78cc1cd.woff2) format("woff2"),
    url(/static/media/Druk-HeavyItalic.b2c3d5ce.woff) format("woff"),
    url(/static/media/Druk-HeavyItalic.b13ec45b.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-BoldItalic";
  src: url(/static/media/Druk-BoldItalic.13e1d916.woff2) format("woff2"),
    url(/static/media/Druk-BoldItalic.6b48a0d8.woff) format("woff"),
    url(/static/media/Druk-BoldItalic.243a095c.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-MediumItalic";
  src: url(/static/media/Druk-MediumItalic.813a9494.woff2) format("woff2"),
    url(/static/media/Druk-MediumItalic.2f3c04b2.woff) format("woff"),
    url(/static/media/Druk-MediumItalic.305313a7.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-Bold";
  src: url(/static/media/Druk-Bold.2a8abb3b.woff2) format("woff2"),
    url(/static/media/Druk-Bold.3a1b9cd8.woff) format("woff"),
    url(/static/media/Druk-Bold.45261dff.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-Heavy";
  src: url(/static/media/Druk-Heavy.67bb90cc.woff2) format("woff2"),
    url(/static/media/Druk-Heavy.1c9dfa36.woff) format("woff"),
    url(/static/media/Druk-Heavy.17c02b92.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-SuperItalic";
  src: url(/static/media/Druk-SuperItalic.39ebc561.woff2) format("woff2"),
    url(/static/media/Druk-SuperItalic.96a6219e.woff) format("woff"),
    url(/static/media/Druk-SuperItalic.f207d4e0.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-Super";
  src: url(/static/media/Druk-Super.30f332ec.woff2) format("woff2"),
    url(/static/media/Druk-Super.89d42259.woff) format("woff"),
    url(/static/media/Druk-Super.fe0a22f8.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukCondensed-Super";
  src: url(/static/media/DrukCondensed-Super.d0b1d5db.woff2)
      format("woff2"),
    url(/static/media/DrukCondensed-Super.3182737a.woff) format("woff"),
    url(/static/media/DrukCondensed-Super.38bf3886.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukXXCondensed-Super";
  src: url(/static/media/DrukXXCondensed-Super.516cc712.woff2)
      format("woff2"),
    url(/static/media/DrukXXCondensed-Super.b5d8e501.woff) format("woff"),
    url(/static/media/DrukXXCondensed-Super.5e17e035.ttf)
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukCondensed-SuperItalic";
  src: url(/static/media/DrukCondensed-SuperItalic.db36a9f9.woff2)
      format("woff2"),
    url(/static/media/DrukCondensed-SuperItalic.7aaaba1a.woff)
      format("woff"),
    url(/static/media/DrukCondensed-SuperItalic.ce908646.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukXCondensed-SuperItalic";
  src: url(/static/media/DrukXCondensed-SuperItalic.87d066ac.woff2)
      format("woff2"),
    url(/static/media/DrukXCondensed-SuperItalic.34241ac8.woff)
      format("woff"),
    url(/static/media/DrukXCondensed-SuperItalic.ed8fc8ee.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukXXCond-SuperItalic";
  src: url(/static/media/DrukXXCond-SuperItalic.808ec124.woff2)
      format("woff2"),
    url(/static/media/DrukXXCond-SuperItalic.b04880e5.woff) format("woff"),
    url(/static/media/DrukXXCond-SuperItalic.dfb08a06.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukXCondensed-Super";
  src: url(/static/media/DrukXCondensed-Super.c16f8d9b.woff2)
      format("woff2"),
    url(/static/media/DrukXCondensed-Super.6a0b2173.woff) format("woff"),
    url(/static/media/DrukXCondensed-Super.0e75ce78.ttf)
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-WideHeavyItalic";
  src: url(/static/media/Druk-WideHeavyItalic.0352f180.woff2)
      format("woff2"),
    url(/static/media/Druk-WideHeavyItalic.74ceeddc.woff) format("woff"),
    url(/static/media/Druk-WideHeavyItalic.58794e0f.ttf)
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DrukWide-BoldItalic";
  src: url(/static/media/DrukWide-BoldItalic.cb764e30.woff2)
      format("woff2"),
    url(/static/media/DrukWide-BoldItalic.4f48cf6d.woff) format("woff"),
    url(/static/media/DrukWide-BoldItalic.0109f8d5.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-WideMedium";
  src: url(/static/media/Druk-WideMedium.b30c3332.woff2) format("woff2"),
    url(/static/media/Druk-WideMedium.30b31ad2.woff) format("woff"),
    url(/static/media/Druk-WideMedium.4b129b13.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukWide-Heavy";
  src: url(/static/media/DrukWide-Heavy.dd6f1608.woff2) format("woff2"),
    url(/static/media/DrukWide-Heavy.76da21bf.woff) format("woff"),
    url(/static/media/DrukWide-Heavy.44e90c5d.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukWide-Bold";
  src: url(/static/media/DrukWide-Bold.b8ec96ee.woff2) format("woff2"),
    url(/static/media/DrukWide-Bold.1d3fab75.woff) format("woff"),
    url(/static/media/DrukWide-Bold.bf92722d.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Druk-WideMediumItalic";
  src: url(/static/media/Druk-WideMediumItalic.4e912548.woff2)
      format("woff2"),
    url(/static/media/Druk-WideMediumItalic.a1af3c0a.woff) format("woff"),
    url(/static/media/Druk-WideMediumItalic.f697dd55.ttf)
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Druk-WideSuper";
  src: url(/static/media/Druk-WideSuper.c3b8570f.woff2) format("woff2"),
    url(/static/media/Druk-WideSuper.be7cca37.woff) format("woff"),
    url(/static/media/Druk-WideSuper.ee23f829.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DrukWide-SuperItalic";
  src: url(/static/media/DrukWide-SuperItalic.99f26a6a.woff2)
      format("woff2"),
    url(/static/media/DrukWide-SuperItalic.0c06490d.woff) format("woff"),
    url(/static/media/DrukWide-SuperItalic.fa97dcc6.ttf)
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Heavy";
  src: url(/static/media/Termina-Heavy.2873ea7a.woff2) format("woff2"),
    url(/static/media/Termina-Heavy.b51ee5ce.woff) format("woff"),
    url(/static/media/Termina-Heavy.e5d1520e.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Black";
  src: url(/static/media/Termina-Black.afa567d5.woff2) format("woff2"),
    url(/static/media/Termina-Black.6a7bc332.woff) format("woff"),
    url(/static/media/Termina-Black.5d2a9241.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina";
  src: url(/static/media/Termina-Bold.c8239635.woff2) format("woff2"),
    url(/static/media/Termina-Bold.1595163e.woff) format("woff"),
    url(/static/media/Termina-Bold.ba3b7059.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Regular";
  src: url(/static/media/Termina-Regular.994ca9ed.woff2) format("woff2"),
    url(/static/media/Termina-Regular.cfa740ed.woff) format("woff"),
    url(/static/media/Termina-Regular.0a0d17f0.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Thin";
  src: url(/static/media/Termina-Thin.714d41e7.woff2) format("woff2"),
    url(/static/media/Termina-Thin.42b4ddcd.woff) format("woff"),
    url(/static/media/Termina-Thin.250b17da.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-ExtraLight";
  src: url(/static/media/Termina-ExtraLight.56af4087.woff2)
      format("woff2"),
    url(/static/media/Termina-ExtraLight.9c0bad39.woff) format("woff"),
    url(/static/media/Termina-ExtraLight.3bfec47a.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Demi";
  src: url(/static/media/Termina-Demi.d050f4e8.woff2) format("woff2"),
    url(/static/media/Termina-Demi.33980795.woff) format("woff"),
    url(/static/media/Termina-Demi.d667d1d9.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Medium";
  src: url(/static/media/Termina-Medium.90a7a56f.woff2) format("woff2"),
    url(/static/media/Termina-Medium.8ac41054.woff) format("woff"),
    url(/static/media/Termina-Medium.7caf6d6c.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Termina-Light";
  src: url(/static/media/Termina-Light.c5a6d0d9.woff2) format("woff2"),
    url(/static/media/Termina-Light.2e16769f.woff) format("woff"),
    url(/static/media/Termina-Light.650fce53.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

:root {
  --color--cta: rgba(158, 100, 152, 1);
  --color--cta--hover: rgba(158, 231, 152, 1);
}

.button_color {
  stroke: rgba(158, 100, 152, 1);
  stroke: var(--color--cta);
  fill: rgba(158, 100, 152, 1);
  fill: var(--color--cta);
  transition: all 0.3s;
}

.button_color_hover {
  stroke: rgba(158, 231, 152, 1);
  stroke: var(--color--cta--hover);
  fill: rgba(158, 231, 152, 1);
  fill: var(--color--cta--hover);
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
  transition: all 0.3s;
}

.button_color_hover_black {
  stroke: rgba(158, 231, 152, 1);
  stroke: var(--color--cta--hover);
  fill: rgba(158, 231, 152, 1);
  fill: var(--color--cta--hover);
  -webkit-filter: contrast(0.8);
          filter: contrast(0.8);
  transition: all 0.3s;
}

